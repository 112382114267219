import { render, staticRenderFns } from "./kodeImage.vue?vue&type=template&id=12d983ef&"
import script from "./kodeImage.vue?vue&type=script&lang=js&"
export * from "./kodeImage.vue?vue&type=script&lang=js&"
import style0 from "./kodeImage.vue?vue&type=style&index=0&id=12d983ef&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports